@import '../../styles/variables';

// Grouping related properties together
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 1;
  top: 0;
  padding: 1em 4em;
  background-color: var(--background-color);
  color: var(--text-color);
  
  // Adding comments to provide more context
  // Logo styles
  .logo {
    font-weight: bold;
    font-size: 1.4em;
    color: $primary-color;
    text-decoration: overline;
  }

  .nav-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 2em;

    .nav-items {
      display: flex;
      gap: 1em;

      a {
        // Text styles grouped together
        text-decoration: none;
        text-transform: uppercase;
        color: var(--text-color);
        
        // Box model properties grouped together
        padding: 1em 0.4em;
        transition: all 0.3s ease-in-out;

        &.active-link {
          color: var(--primary-color);
          border-bottom: 2px solid var(--primary-color);
        }

        &:hover {
          color: var(--primary-color);
          transform: scale(1.01);
          background-color: rgba(0,0,0,0.1);
        }
      }
    }
  }

  .hamburger-menu {
    display: none;
  }
}

// Mobile view
// Leveraging SCSS features by using a variable for the breakpoint
$mobile-breakpoint: 768px;
@media screen and (max-width: $mobile-breakpoint) {
  nav {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 1em 1em;

    .logo {
      font-size: 1.15em;
    }

    .nav-content {
      justify-content: space-between;
      width: 100%;
      padding-left: 0em;

      .nav-items {
        display: none;
        width: 100%;
        gap: 0em;
        text-align: center;
        font-size: .9em;

        &.open {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        a {
          display: inline-block;
          text-align: center;
          width: auto;
        }
      }
    }

    .hamburger-menu {
      display: block;
      margin-right: .2em;
      padding: .4em .8em;
    }
  }
}
