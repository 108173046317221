.theme-toggle {
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: transparent;
  color: inherit;
  border: 1px solid currentColor;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
