@import '../styles/variables';

.landing-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(45deg, $primary-color, $secondary-color);
  overflow-x: hidden;
}

.hero {
  min-height: 100vh; // makes the hero section fullscreen
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--text-color);
}

.title {
  font-size: 4rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.2rem;
}

.subtitle {
  font-size: 1.5rem;
  margin: 1rem 0;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.content {
  background-color: var(--background-color);
  padding: 2rem; // adjust this if needed
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  width: 100%; // full width
  box-sizing: border-box; // include padding and border in the element's total width
}

.buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

button {
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 2rem;
  border-radius: 0.25rem;
  background-color: var(--button-background);
  color: var(--button-text-color);
  border: 1px solid var(--button-border-color);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

button:hover {
  filter: brightness(85%);
}

.content {
  background-color: var(--background-color);
  padding: 4rem;
  display: grid;
}

.excerpt-sections {
  display: grid;
  grid-template-columns: repeat(1, 1fr); // Start with a single column
  gap: 2rem;
  width: 100%;
  box-sizing: border-box;
  
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns on larger screens
  }
  
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr); // 3 columns on even larger screens
  }
}

.excerpt {
  background: var(--background-color);
  border-radius: 15px;
  padding: 1rem; // adjust this as necessary, it could be causing overflow
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  height: auto; // let the browser calculate the height based on content
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  h2 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-weight: bold;
  }

  p {
    color: var(--text-color);
    line-height: 1.6;
    position: relative;
    z-index: 1;
    color: var(--text-color);
    line-height: 1.6;
    font-weight: 300;
  }

  .read-more {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.1s ease-in-out;
    align-self: end;
    position: relative;
    z-index: 1;

    &:hover, &:focus {
      color: var(--secondary-color);
      background-color: var(--primary-color); // Change background color
      padding: 5px 10px; // Add some padding
      border-radius: 5px; // Round the corners
      transition: all 0.3s ease-in-out; // Smooth the color transition
    }
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%;
    background: linear-gradient(transparent, rgba(0,0,0,0.7));
    z-index: 0;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    transform: translateY(100%);
  }

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

    &:before {
      transform: translateY(0%);
    }
  }
}
