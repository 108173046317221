@import './variables';

:root {
  --background-color: #{$dark-background};
  --text-color: #{$dark-text};
  --button-background: #{$secondary-color};
  --button-text-color: #{$dark-text};
  --button-border-color: #{$secondary-color};
}

body[data-theme='light'] {
  --background-color: #{$light-background};
  --text-color: #{$light-text};
  --button-background: #{$light-text};
  --button-text-color: #{$secondary-color};
  --button-border-color: #{$secondary-color};
}
