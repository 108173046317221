// Typography
h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  // Layout
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  // Utility Classes
  .flex {
    display: flex;
  }
  
  .flex-column {
    flex-direction: column;
  }
  
  .space-between {
    justify-content: space-between;
  }
  
  .space-around {
    justify-content: space-around;
  }
  
  .center {
    justify-content: center;
    align-items: center;
  }
  
  .text-center {
    text-align: center;
  }
  