@import '../../styles/variables';

.projects-section {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  h2 {
    color: var(--primary-color);
    font-weight: bold;
  }
}

.artstation-projects {
  width: 100%;
  max-width: 800px;

  .slick-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    img {
      max-width: 100%;
      border-radius: 15px;
      object-fit: cover;
    }

    h3 {
      color: var(--primary-color);
      font-weight: bold;
    }

    p {
      color: var(--text-color);
      line-height: 1.6;
      font-weight: 300;
    }

    a {
      color: var(--primary-color);
      text-decoration: none;
      transition: color 0.1s ease-in-out;

      &:hover,
      &:focus {
        color: var(--secondary-color);
      }
    }
  }
}

.github-projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;

  .project {
    background-color: var(--background-color);
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h3 {
      color: var(--primary-color);
      font-weight: bold;
    }

    p {
      color: var(--text-color);
      line-height: 1.6;
      font-weight: 300;
    }

    a {
      color: var(--primary-color);
      text-decoration: none;
      transition: color 0.1s ease-in-out;

      &:hover,
      &:focus {
        color: var(--secondary-color);
      }
    }
  }
}
