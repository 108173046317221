@import '../../styles/variables';

.video-games {
  .subcategories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 2rem;
    padding: 2rem 0;
  }

  .subcategory {
    padding: 1rem;
    background-color: $primary-color;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    text-align: center;
  }
}
