@import '../../styles/variables';

.master-page {
  padding: 2rem;
  color: var(--text-color);

  h1 {
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    text-align: center;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6;
    text-align: justify;
  }
}
